<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/structure' }">
        <app-i18n code="entities.structure.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        v-if="structureId"
        :to="{ path: getPathViaMode }"
      >
        <app-i18n :code="getTextViaMode"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.structure.view.titleSI"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.structure.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-struct-item-view-toolbar
        :structureId="structureId"
        v-if="record && !this.IsViewMode"
      ></app-struct-item-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.id.label"
          :value="presenter(record, 'id')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.order.label"
          :value="presenter(record, 'order')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.count.label"
          :value="presenter(record, 'count')"
        ></app-view-item-text>

        <app-view-item-relation-to-one
          :label="fields.questionType.label"
          :permission="fields.questionType.readPermission"
          :url="fields.questionType.viewUrl"
          :value="presenter(record, 'questionType')"
        ></app-view-item-relation-to-one>

        <app-view-item-relation-to-many
          :label="fields.structItemGroups.label"
          :permission="fields.structItemGroups.readPermission"
          :url="fields.structItemGroups.viewUrl"
          :value="presenter(record, 'structItemGroups')"
        ></app-view-item-relation-to-many>

        <app-view-item-text
          :label="fields.statusSI.label"
          :value="presenter(record, 'statusSI')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.resultEffect.label"
          :value="presenter(record, 'resultEffect')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import StructItemViewToolbar from '@/modules/structure/components/struct-item-view-toolbar.vue';
import { StructItemModel } from '@/modules/structure/struct-item-model';

const { fields } = StructItemModel;

export default {
  name: 'app-structure-view-page',

  props: ['structureId', 'id', 'mode'],

  components: {
    [StructItemViewToolbar.name]: StructItemViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'structure/viewSI/record',
      loading: 'structure/viewSI/loading',
    }),
    getPathViaMode() {
      return this.IsViewMode
        ? `/structure/${this.structureId}`
        : `/structure/${this.structureId}/edit`;
    },
    getTextViaMode() {
      return this.IsViewMode
        ? 'entities.structure.view.title'
        : 'entities.structure.edit.title';
    },
    IsViewMode() {
      return this.mode === 'vm';
    },
    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id, this.structureId);
  },

  methods: {
    ...mapActions({
      doFind: 'structure/viewSI/doFind',
    }),

    presenter(record, fieldName) {
      return StructItemModel.presenter(record, fieldName);
    },
  },
};
</script>
